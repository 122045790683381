import React from 'react'
import { Nav, Navbar } from 'react-bootstrap'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { colors } from '../../styles/variables'
import { getEmSize, getPhoneByFormat } from '../../styles/mixins'

const StyledMenuItemLink = styled(Link)`
  fontsize: ${getEmSize(32)}rem;
  color: black;
  text-decoration: none;

  &:hover {
    color: ${colors.warning} !important;
  }
`

const menu: MenuItem[] = [
  { name: 'Покупателям', path: '/customer' },
  { name: 'Оплата', path: '/payment' },
  { name: 'Доставка', path: '/delivery' },
  { name: 'Гарантия', path: '/warranty' },
  { name: 'Контакты', path: '/contact' },
  { name: 'Партнеры', path: '/partner' }
]

type MenuItem = {
  name: string
  path: string
}

type NavigationTopProps = {
  logotype: string
  phone: number
}

const NavigationTop: React.FC<NavigationTopProps> = (data: NavigationTopProps) => (
  <Navbar expand="lg" variant="light" bg="white" style={{ padding: '10px' }} className="w-100">
    <StyledMenuItemLink style={{ textTransform: 'uppercase' }} to="/">
      {data.logotype}
    </StyledMenuItemLink>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse className="justify-content-end">
      <Nav>
        {menu.map(item => (
          <StyledMenuItemLink className="nav-link" key={item.name.toString()} to={item.path}>
            {item.name}
          </StyledMenuItemLink>
        ))}
      </Nav>
    </Navbar.Collapse>

    <Navbar.Text className="d-none d-lg-block">
      <a style={{ color: colors.black }} href={`tel:${getPhoneByFormat(data.phone.toString(), '+7xxxxxxxxxx')}`}>
        {getPhoneByFormat(data.phone.toString(), '+7 (xxx) xxx-xx-xx')}
      </a>
    </Navbar.Text>
  </Navbar>
)

export default NavigationTop
