import { dimensions } from './variables'

export const getEmSize = (size: number) => size / dimensions.fontSize.regular

export const getPhoneByFormat = (phone: string, format: string) => {
  let output = ''
  let phoneCursor = 0
  let formatCursor = 0

  if (format.charAt(formatCursor) === '+') {
    output += format.charAt(formatCursor)
    formatCursor += 1
  }

  if (phone.length === 10) {
    output += format.charAt(formatCursor)
    formatCursor += 1
  }

  for (let i = formatCursor; i < format.length; i += 1) {
    const c = format.charAt(i)
    if (c === ' ' || c === '-' || c === '(' || c === ')') {
      output += c
    } else if (c === 'x' && phoneCursor < phone.length) {
      output += phone.charAt(phoneCursor)
      phoneCursor += 1
    }
  }

  return output
}
