import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

type FooterBottomProps = {
  copyright: string
}

const FooterBottom: React.FC<FooterBottomProps> = (data: FooterBottomProps) => (
  <Container fluid style={{ borderTop: '1px solid rgba(102,102,102,0.2)' }}>
    <Row>
      <Col>
        <p style={{ margin: '0', color: 'rgb(62, 62, 62)' }} className="text-center">
          {data.copyright}
        </p>
      </Col>
    </Row>
  </Container>
)

export default FooterBottom
