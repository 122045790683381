import React from 'react'
import { Row } from 'react-bootstrap'
import styled from '@emotion/styled'
import { getPhoneByFormat } from '../../styles/mixins'

const StyledContactList = styled.ul`
  margin: 0;
  padding: 0 15px;
  list-style-type: none;
  li {
    margin-bottom: 30px;
    :last-child {
      margin-bottom: 0;
    }

    a :hover {
      color: #fff;
    }

    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 60px;
      height: 60px;
      margin-right: 20px;
      border-radius: 50%;
      background: #c91212;
      color: #fff;
    }
  }
`

const StyledContactText = styled.div`
  h6 {
    color: #fff;
  }

  p {
    color: #666;
  }

  a {
    color: #666;
    text-decoration: none;
  }

  h6,
  p {
    margin: 0;
  }
`

type ContactListProps = {
  address: {
    zip: number
    city: string
    street: string
    house: number
  }
  contacts: {
    phone: number
    email: string
  }
}

const ContactList: React.FC<ContactListProps> = (data: ContactListProps) => (
  <StyledContactList>
    <Row as="li" className="align-items-center">
      <div className="icon">
        <span>A</span>
      </div>
      <StyledContactText>
        <h6>Адрес</h6>
        <p>
          {data.address.zip}, г. {data.address.city}
        </p>
        <p>
          ул. {data.address.street}, д. {data.address.house}
        </p>
      </StyledContactText>
    </Row>

    <Row as="li" className="align-items-center">
      <div className="icon">
        <span>B</span>
      </div>
      <StyledContactText>
        <h6>Эл. почта</h6>
        <a href={`mailto:${data.contacts.email}`}>{data.contacts.email}</a>
      </StyledContactText>
    </Row>

    <Row as="li" className="align-items-center">
      <div className="icon">
        <span>C</span>
      </div>
      <StyledContactText>
        <h6>Телефон</h6>
        <a href={`tel:${getPhoneByFormat(data.contacts.phone.toString(), '+7xxxxxxxxxx')}`}>
          {getPhoneByFormat(data.contacts.phone.toString(), '+7 (xxx) xxx-xx-xx')}
        </a>
      </StyledContactText>
    </Row>
  </StyledContactList>
)

export default ContactList
