import React from 'react'
import { Container, Row } from 'react-bootstrap'
import { graphql, StaticQuery } from 'gatsby'
import NavigationTop from './NavigationTop'

type HeaderInfoQuery = {
  site: {
    siteMetadata: {
      company: {
        logotype: string
        contacts: {
          phone: number
        }
      }
    }
  }
}

const Header: React.FC = () => (
  <StaticQuery
    query={graphql`
      query HeaderInfoQuery {
        site {
          siteMetadata {
            company {
              logotype
              contacts {
                phone
              }
            }
          }
        }
      }
    `}
    render={(data: HeaderInfoQuery) => (
      <header>
        <Container>
          <Row>
            <NavigationTop logotype={data.site.siteMetadata.company.logotype} phone={data.site.siteMetadata.company.contacts.phone} />
          </Row>
        </Container>
      </header>
    )}
  />
)

export default Header
