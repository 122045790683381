import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { graphql, StaticQuery } from 'gatsby'
import ContactList from './ContactList'
import FooterBottom from './FooterBottom'
import { getEmSize } from '../../styles/mixins'

type FooterInfoQuery = {
  site: {
    siteMetadata: {
      company: {
        name: string
        copyright: string
        address: {
          zip: number
          city: string
          street: string
          house: number
        }
        contacts: {
          phone: number
          email: string
        }
      }
    }
  }
}

const Footer: React.FC = () => (
  <StaticQuery
    query={graphql`
      query FooterInfoQuery {
        site {
          siteMetadata {
            company {
              name
              copyright
              address {
                zip
                city
                street
                house
              }
              contacts {
                phone
                email
              }
            }
          }
        }
      }
    `}
    render={(data: FooterInfoQuery) => (
      <footer style={{ background: '#151515' }}>
        <Container fluid>
          <Container style={{ padding: '30px 0' }}>
            <Row>
              <Col xs={12} sm={12} md={5} lg={6}>
                <ContactList address={data.site.siteMetadata.company.address} contacts={data.site.siteMetadata.company.contacts} />
              </Col>
              <Col>
                <h5 style={{ margin: '0', fontSize: `${getEmSize(42)}rem` }} className="text-white text-right">
                  {data.site.siteMetadata.company.name}
                </h5>
              </Col>
            </Row>
          </Container>
        </Container>
        <FooterBottom copyright={data.site.siteMetadata.company.copyright} />
      </footer>
    )}
  />
)

export default Footer
